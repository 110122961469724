.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .fade-in.visible {
    opacity: 1;
  }
  
  .fade-in.visible.delay-1 {
    transition-delay: 0.5s;
  }
  
  .fade-in.visible.delay-2 {
    transition-delay: 1s;
  } 