.productos-select {
  border-width: 1px;
  border-left: #FBFBFB;
  border-top: #FBFBFB;
  background-color: #FFFFFF;
  box-shadow: none;
  border-radius: 0;
  text-align: center;
  border-bottom-color: gray;
  border-right-color: gray;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
  outline: none;
}

.label-style {
  font-size: 25px;
  line-height: 30.48px;
  text-align: left;
  display: block;
  font-weight: 100;
  padding-bottom: 5px;
  padding-top: 10px;
  padding-left: 17px;
}

.btn-style {
  width: 100%;
  position: reative;
  background: #fBfBfB;
  color: #0171E3;
  border: 1px solid;
  padding: 8px 16px;
  transition: all 0.3s ease;
  overflow: hidden;

}

.text-area-style {
  width: 100%;
}

.contenedor {
  height: 100%;
}

.texto_especialidad {
  text-align: justify;
  color: gray;
}

.warning-message {
  color: red;
  font-size: 10px;
  text-align: left;
  margin-left: 5px;
}