.footer {

  height: 350px;
  background: black;
  /* lightgrey; */
}

.links {
  display: flex;
}

.links a {
  display: flex;
  margin-top: 20px;
}

.hypr {
  width: 100%;
}

.name {
  font-size: 35px;
}

.col_aux {
  width: 33%;
}
