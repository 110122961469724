.screen {
  height: 100%;
}

.box-login-aux {
  z-index: 20;
  width: 100%;
  background-color: "#ffffff";
  box-shadow: none;
}

.espacio-box-login-form {
  border-color: black;
  font-family: "Satoshi-Bold";
  overflow-x: auto;
}

.register-gray-box {
  height: 400px;
  width: 100%;
  color: white;
  background-color: #86868b;
}

.registrate-text {
  font-size: 77px;
  text-align: left;
  padding-top: 20px;
  padding: 30px;
  font-weight: 700;
}

.registrate-text span {
  font-style: bold;
}

.promocion-text {
  font-size: 60px;
  font-weight: 100;
  text-align: left;
  padding-left: 30px;
}

.fondo-form {
  background-color: #fbfbfb;
}

.estilo_label {
  margin-bottom: 40px;
  font-size: 25px;
  text-align: left;
  border-width: 1px;
  border-left: #fbfbfb;
  border-top: #fbfbfb;
  background-color: #fbfbfb;
  box-shadow: none;
  border-radius: 0;
  border-bottom: gray;
  border-right-color: gray;
  width: 100%;
  outline: none;
}

.form-tag {
  color: black;
}

.foto-div {
  align-content: center;
}

.foto-conditional-div {
  margin-top: 25%;
  width: 100%;
}

.load-photo {
  width: 40%;
  height: 40vh;
  border-style: solid;
  border-width: 2px;
  cursor: grab;
}

.load-photo img {
  display: block;
  margin: auto;
}

.password-input {
  align-content: center;
}

.description-input {
  background-color: #f8f8f8;
  border-radius: 0px;
  width: 100%;
  margin: auto;
}

.fechas-input {
  margin-top: 50;
}

.estilo_label.derecha {
  border-left-color: gray;
  border-right-color: #fbfbfb;
  border-bottom-color: gray;
  padding-left: 22px;
}

.estilo_input {
  border-width: 1px;
  border-left: #FBFBFB;
  border-top: #FBFBFB;
  background-color: #FBFBFB;
  box-shadow: none;
  border-radius: 0;
  text-align: center;
  border-bottom-color: gray;
  border-right-color: gray;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
  padding: 5px;
  outline: none;
}

.estilo_input.derecha {
  border-left: gray;
  background-color: #FBFBFB;
  border-radius: 0;
  border-bottom-color: gray;
  border-right-color: #fbfbfb;
}

.subtitulos {
  font-size: 40px;
  text-align: left;
  border-width: 1px;
  border-left: #fbfbfb;
  border-top: #fbfbfb;
  background-color: #fbfbfb;
  box-shadow: none;
  border-radius: 0;
  border-bottom: gray;
  border-right-color: gray;
  width: 100%;
  outline: none;

}

.subtitulos.dos {
  font-weight: 500;
  font-size: 35px;
  padding-left: 25px;
  text-align: left;
  border-width: 1px;
  border-left: #fbfbfb;
  border-top: #fbfbfb;
  background-color: #fbfbfb;
  box-shadow: none;
  border-radius: 0;
  border-bottom: gray;
  border-right-color: gray;
  width: 100%;
  outline: none;

}

.estilo_texto {
  font-size: 25px;
  line-height: 1.5;
  font-weight: lighter;
  margin-top: 60px;
  margin-right: 50px;
  margin-left: 50px;
  text-align: justify;
}

.estilo_boton {
  margin-left: 60%;
  margin-bottom: 20px;
  font-size: 24;
  color: #FFFFFF;
  border-radius: 20px;
  background-color: #0171E3;
  border-color: #0171E3;
  width: 147px;
  padding: 5px;
  box-shadow: none;

}

.estilo_cuadro {
  border-color: gray;
  border-width: 1px;
  height: 307px;
  background-color: #fbfbfb;
  width: 75%;
  margin-left: 24px;
  border-style: solid;
  border-top: none;
}

.estilo_select {
  color: gray;
  border-radius: none;
  background-color: #FBFBFB;
  border-width: 1px;
  border-color: gray;
  padding: 3px;
  text-align: center;
}

.align-items-center {
  display: flex;
  align-items: center;
}