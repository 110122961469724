@media only screen and (max-width: 600px){
   .buttonStyle{
        color: black;   
        font-size: 20px;
        background-color: #fbfbfb;
        border-color: #fbfbfb;
        border-radius: 7px;
        padding: 5px;
        box-shadow: none;
   } 
}

@media only screen and (min-width: 601px){
    .buttonStyle{
        font-size: 40px ;
        background-color: #fbfbff;
        border-color: #fbfbff;
        border-radius: 7px;
        width: 495px;
        padding: 5px;
        box-shadow: none;
        margin-right: 50px;
}
}
