@keyframes fadeInTop {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fade-in-top {
    opacity: 0;
    transition: opacity 2s ease-in-out, transform 2s ease-in-out;
  }
  
  .fade-in-top.visible {
    animation: fadeInTop 2s ease-in-out;
  }
  